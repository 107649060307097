import axios from "axios";
import Cookies from "js-cookie";
import ApiUrl from "../../config/ApiUrl";
import format from "date-fns/format";

const apiToken = Cookies.get("apiToken", {path: "/"});

async function pops()
{
    let pops = null;

    if(localStorage.getItem("pops-list") === null) {
        pops = await axios.get(ApiUrl.pops.list, {
            headers: {
                "X-Authorization" : apiToken
            }
        });

        localStorage.setItem("pops-list", JSON.stringify(pops));
    }

    return JSON.parse(localStorage.getItem("pops-list"));
}

async function customPropertyPlans(partnerId)
{
    return await axios.get(ApiUrl.bandwidths.buildingCustomPlans + "/" + partnerId, {
        headers: {
            "X-Authorization" : apiToken
        }
    });
}

async function customPlans()
{
    let customPlans = null;

    if(localStorage.getItem("property-custom-plans") === null) {
        customPlans = await axios.get(ApiUrl.bandwidths.customPlans, {
            headers: {
                "X-Authorization" : apiToken
            }
        });

        localStorage.setItem("property-custom-plans", JSON.stringify(customPlans));
    }

    return JSON.parse(localStorage.getItem("property-custom-plans"));
}

async function createProperty(formFields)
{
    return await axios.post(ApiUrl.properties.create, formFields, {
        headers: {
            "X-Authorization" : apiToken
        }
    });
}

async function partnerWiseProperties(partnerId = null)
{
    const url = partnerId ? `${ApiUrl.properties.partnerWiseProperties}?partner_id=${partnerId}` : `${ApiUrl.properties.partnerWiseProperties}`;

    const cacheKey = `partners-properties-${partnerId}`;

    let partnerProperties = null;

    if(localStorage.getItem(cacheKey) === null) {
        partnerProperties = await axios.get(url, {
            headers: {
                "X-Authorization" : apiToken
            }
        });

        localStorage.setItem(cacheKey, JSON.stringify(partnerProperties));
    }

    return JSON.parse(localStorage.getItem(cacheKey));
}

async function propertiesList(partnerCode)
{
    return await axios.get(`${ApiUrl.properties.list}?partner_id=${partnerCode}`, {
        headers: {
            "X-Authorization" : apiToken
        }
    });
}

async function propertyMapLocations()
{
    let propertyLocations = null;

    if(localStorage.getItem("properties-map-locations") === null) {
        propertyLocations = await axios.get(ApiUrl.properties.mapLocations, {
            headers: {
                "X-Authorization" : apiToken
            }
        });

        localStorage.setItem("properties-map-locations", JSON.stringify(propertyLocations));
    }

    return JSON.parse(localStorage.getItem("properties-map-locations"));
}

async function upTimeData(formFields)
{
    return await axios.get(`${ApiUrl.properties.upTimeData}?businessDabbaId=${formFields.business_dabba_id}&fromDate=${format(formFields.from_date, "yyyy-MM-dd")}&toDate=`, {
        headers: {
            "X-Authorization" : apiToken
        }
    });
}

export {
    pops,
    customPropertyPlans,
    customPlans,
    createProperty,
    partnerWiseProperties,
    propertiesList,
    propertyMapLocations,
    upTimeData
};
