import axios from "axios";
import Cookies from "js-cookie";
import ApiUrl from "../../config/ApiUrl";

const apiToken = Cookies.get("apiToken", {path: "/"});

async function logout ()
{
    return await axios.post(ApiUrl.auth.logout, {}, {
        headers: {
            "X-Authorization" : apiToken
        }
    });
}

export {
    logout
};
