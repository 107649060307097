import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import FreePlans from "../../components/userPlanDetails/FreePlans";
import PlanDetails from "../../components/userPlanDetails/PlanDetails";
import UserDeviceDetails from "../../components/userPlanDetails/UserDeviceDetails";
import UserRegistrationDetails from "../../components/userPlanDetails/UserRegistrationDetails";
import DashboardLayout from "../../layouts/DashboardLayout";
import { userPlanDetails } from "../../services/customers/CustomerService";
import userPlanDetailsValidation from "../../validations/customers/userPlanDetailsValidation";
import axios from "../../helpers/axios";
import { useSearchParams } from "react-router-dom";

function UserPlanDetails() {
  const [userPlan, setUserPlan] = useState(null);
  const [searchParams] = useSearchParams();
  const phone = searchParams.get("phone");

  const currentPlan = userPlan?.user_plans?.current_plan
    ? [userPlan?.user_plans?.current_plan]
    : [];
  const upcomingPlans = userPlan?.user_plans?.upcoming_plans || [];
  const expiredPlans = userPlan?.user_plans?.expired_plans || [];
  const freePlans = userPlan?.user_plans?.free_plans || [];

  console.table({ currentPlan, upcomingPlans, expiredPlans, freePlans });

  const getUserPlanDetails = async (values) => {
    try {
      const res = await (
        await axios.get(`api/users/${values.phone}/show`)
      ).data;
      if (res.status === "success") {
        console.log(res)
        setUserPlan(res.data.user);

        // toast.success(res.data.message);
      } else {
        throw new Error("Something went wrong");
      }
    } catch (error) {
     
      toast.error(error.response.data.message);
    }
  };

  useEffect(() => {
    if (phone) {
      getUserPlanDetails({ phone });
    }
  }, [phone]);
  return (
    <Formik
      initialValues={{
        phone: phone ?? "",
      }}
      validationSchema={userPlanDetailsValidation}
      onSubmit={(values, { setFieldError }) => {
        getUserPlanDetails(values);
      }}
    >
      {(props) => (
        <DashboardLayout>
          <div className="flex justify-between align-middle">
            <h1 className="font-bold text-3xl">User Plan Information</h1>
          </div>

          <Form
            onSubmit={props.handleSubmit}
            className="grid grid-cols-12 space-x-4 mt-5"
          >
            <div className="col-span-8 md:col-span-3">
              <label htmlFor="phone">Phone Number *</label>

              <Field type="text" name="phone" id="phone" className="mt-2" />
              <ErrorMessage
                name="phone"
                component="div"
                className="text-red-500 pt-3"
              />

            </div>

            <div className="col-span-4 md:col-span-3 mt-8">
              <button type="submit" className="btn btn-primary">
                Search
              </button>
            </div>
          </Form>

          {userPlan && (
            <>
              <div className="grid grid-cols-12 md:space-x-4 mt-5">
                <UserRegistrationDetails userPlan={userPlan} />

                <UserDeviceDetails userPlan={userPlan} />
              </div>

              {currentPlan.length ? (
                <div className="grid grid-cols-12 space-x-4 mt-5">
                  <PlanDetails plan={currentPlan} planTitle="Current Plan" />
                </div>
              ) : null}
              {upcomingPlans.length ? (
                <div className="grid grid-cols-12 space-x-4 mt-5">
                  <PlanDetails plan={upcomingPlans} planTitle="Upcoming Plan" />
                </div>
              ) : null}
              {expiredPlans.length ? (
                <div className="grid grid-cols-12 space-x-4 mt-5">
                  <PlanDetails plan={expiredPlans} planTitle="Expired Plans" />
                </div>
              ) : null}
              {freePlans.length ? (
                <div className="grid grid-cols-12 space-x-4 mt-5">
                  <PlanDetails plan={freePlans} planTitle="Free Plans" />
                </div>
              ) : null}
            </>
          )}
        </DashboardLayout>
      )}
    </Formik>
  );
}

export default UserPlanDetails;
