const API_URL = process.env.REACT_APP_API_URL;

export default {
    auth : {
        requestOtp: `${API_URL}/api/tools/auth/request-otp`,
        verifyOtp: `${API_URL}/api/tools/auth/verify-otp`,
        logout: `${API_URL}/api/tools/auth/logout`
    },
    
    partners: `${API_URL}/api/cpu/partners`,
    
    pops: {
        list: `${API_URL}/api/pops`
    },
    
    bandwidths: {
        buildingCustomPlans: `${API_URL}/api/building-plans`,
        customPlans: `${API_URL}/api/dabba-custom-plans`,
    },
    
    properties: {
        create: `${API_URL}/api/register-dabba`,
        partnerWiseProperties: `${API_URL}/api/cpu/partner-dabba-list`,
        list: `${API_URL}/api/cpu/dabba-list`,
        mapLocations: `${API_URL}/api/property-info-for-map`,
        upTimeData: `${API_URL}/api/uptime-graph`
    },
    
    payments: {
        createCustomerPlanRecharge: `${API_URL}/api/plan-for-failed-paytm-transcation`
    },
    
    customers: {
        registerUser: `${API_URL}/api`,
        deRegisterUser: `${API_URL}/api/deregister-users`,
        userPlanDetails: `${API_URL}/api/cpu/user-plan-details`,
        userBandwidthConsumption: `${API_URL}/api/cpu/user-plan-bandwidth-usage`,
        temporaryBlockUser: `${API_URL}/api/temporary-block-users`,
        temporaryUnBlockUser: `${API_URL}/api/temporary-unblock-users`,
        addUserDevice: `${API_URL}/api/add-user-device`,
        logoutAllDevice: `${API_URL}/api/logout-devices`,
        activateUserDevice: `${API_URL}/api/activate-user-devices`,
        deActivateUserDevice: `${API_URL}/api/deactivate-user-devices`,
    },
    
    customerSupport: {
        sendSMSNotification: `${API_URL}/api/customer-support/send-notification`
    },

};
