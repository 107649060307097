import axios from "axios";
import Cookies from "js-cookie";
import ApiUrl from "../../config/ApiUrl";

const apiToken = Cookies.get("apiToken", {path: "/"});

async function createCustomerPlanRecharge (formFields)
{
    return await axios.post(ApiUrl.payments.createCustomerPlanRecharge, formFields, {
        headers: {
            "X-Authorization": apiToken
        }
    });
}

export {
    createCustomerPlanRecharge
};
