import axios from "../../helpers/axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DashboardLayout from "../../layouts/DashboardLayout";

function PropertyDevices() {
  let { id } = useParams();

  const [propertyDevices, setPropertyDevices] = useState([]);

  const getAllPropertyDevices = async (propertyId) => {
    try {
      const res = (
        await axios.get(
          `/api/dabbas?hasAccessPoints=true&onlyAccessPoints=true&parentDabbaId=${propertyId}`
        )
      ).data;

      if (res.status === "success") {
        setPropertyDevices(res.data.dabbas);
      }
    } catch (error) {
      console.log(error.response.data);
      toast.error(error.response.data.message);
    }
  };

  useEffect(() => {
    getAllPropertyDevices(id);
  }, [id]);

  return (
    <DashboardLayout>
      <h1 className="font-bold text-3xl mb-6">Dabba Lites</h1>
      <div className="w-full bg-white p-4">
        <table className="table table-border table-hover">
          <thead>
            <tr className="bg-gray-100">
              <th>Sr.No</th>
              <th>WD Number</th>
              <th>Lat / Long</th>
              <th>Property Name</th>
              <th>Ip Address</th>
              <th>Status</th>
              <th>Last Ping On</th>
            </tr>
          </thead>
          <tbody>
            {propertyDevices.length
              ? propertyDevices.map((property, index) => {
                const dateDiffInMilliSeconds = Math.abs(
                  new Date(property?.heartbeat_on) - new Date()
                );

                const dateDiffInMinutes =
                  dateDiffInMilliSeconds / (1000 * 60);

                const status = dateDiffInMinutes > 20 ? "Down" : "Live";

                return (
                  <tr
                    key={property._id}
                    onClick={() => handleRowClick(property._id)}
                    className={status == "Down" && "bg-red-100 text-red-500"}
                  >
                    <td
                      className={`${status == "Down" ? "border-red-500" : ""
                        }`}
                    >
                      {index + 1}
                    </td>
                    <td
                      className={`${status == "Down" ? "border-red-500" : ""
                        }`}
                    >
                      {property.wd_number}
                    </td>
                    <td
                      className={`${status == "Down" ? "border-red-500" : ""
                        }`}
                    >
                      {(property.lat && property.long) || "N/A"}
                    </td>
                    <td
                      className={`${status == "Down" ? "border-red-500" : ""
                        }`}
                    >
                      {property.property_name}
                    </td>
                    <td
                      className={`${status == "Down" ? "border-red-500" : ""
                        }`}
                    >
                      {property.ip_address}
                    </td>
                    <td
                      className={`${status == "Down" ? "border-red-500" : ""
                        }`}
                    >
                      {status}
                    </td>
                    <td
                      className={`${status == "Down" ? "border-red-500" : ""
                        }`}
                    >
                      {property?.heartbeat_on
                        ? new Date(property?.heartbeat_on).toLocaleString()
                        : "N/A"}
                    </td>
                  </tr>
                );
              })
              : null}
          </tbody>
        </table>
      </div>
    </DashboardLayout>
  );
}

export default PropertyDevices;
