import React from "react";
import ReactDOM from "react-dom";
import App from "./App.js";
import "./assets/css/index.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

toast.configure({ theme: "colored", newestOnTop: true, autoClose: 3000 });

ReactDOM.render(
    <div className="tracking-wide">
        <App />
    </div>,
    document.getElementById("root")
);
