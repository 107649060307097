import * as yup from "yup";

const requestOtpValidation = yup.object().shape({
  phone: yup
    .number()
    .typeError("Phone number must be numeric.")
    .required("Phone number field is required.")
    .test(
      "length",
      "Phone number must be exactly 10 digits.",
      (phone) => phone?.toString().length === 10
    ),
});

const verifyOtpValidation = yup.object().shape({
  otp: yup
    .number()
    .typeError("OTP must be numeric.")
    .required("OTP field is required.")
    .test(
      "len",
      "OTP number must be exactly 4 digits.",
      (otp) => otp?.toString().length === 4
    ),
});

export { requestOtpValidation, verifyOtpValidation };
