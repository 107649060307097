import React, { useState, useEffect, useRef } from "react";
import { NavLink, useLocation } from "react-router-dom";
import SidebarLinkGroup from "./SidebarLinkGroup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBolt,
  faBuilding,
  faChartLine,
  faCheck,
  faCity,
  faHeadset,
  faIdBadge,
  faMapLocationDot,
  faMessage,
  faMobileScreenButton,
  faPeopleGroup,
  faPerson,
  faPersonCircleXmark,
  faReceipt,
  faTowerCell,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";

function Sidebar({ sidebarOpen, setSidebarOpen }) {
  const location = useLocation();
  const { pathname } = location;

  const trigger = useRef(null);
  const sidebar = useRef(null);

  const storedSidebarExpanded = localStorage.getItem("sidebar-expanded");
  const [sidebarExpanded, setSidebarExpanded] = useState(
    storedSidebarExpanded === null ? false : storedSidebarExpanded === "true"
  );

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!sidebar.current || !trigger.current) return;
      if (
        !sidebarOpen ||
        sidebar.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setSidebarOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!sidebarOpen || keyCode !== 27) return;
      setSidebarOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  useEffect(() => {
    localStorage.setItem("sidebar-expanded", sidebarExpanded);
    if (sidebarExpanded) {
      document.querySelector("body").classList.add("sidebar-expanded");
    } else {
      document.querySelector("body").classList.remove("sidebar-expanded");
    }
  }, [sidebarExpanded]);

  return (
    <div>
      {/* Sidebar backdrop (mobile only) */}
      <div
        className={`fixed inset-0 bg-slate-900 bg-opacity-30 z-40 lg:hidden lg:z-auto transition-opacity duration-200 ${
          sidebarOpen ? "opacity-100" : "opacity-0 pointer-events-none"
        }`}
        aria-hidden="true"
      ></div>

      {/* Sidebar */}
      <div
        id="sidebar"
        ref={sidebar}
        className={`flex flex-col absolute z-40 left-0 top-0 lg:static lg:left-auto lg:top-auto lg:translate-x-0 transform h-screen overflow-y-scroll lg:overflow-y-auto no-scrollbar w-64 lg:w-64 lg:sidebar-expanded:!w-64 2xl:!w-64 shrink-0 bg-slate-800 p-4 transition-all duration-200 ease-in-out ${
          sidebarOpen ? "translate-x-0" : "-translate-x-64"
        }`}
      >
        {/* Sidebar header */}
        <div className="flex justify-between pr-3 sm:px-2">
          {/* Close button */}
          <button
            ref={trigger}
            className="lg:hidden text-slate-500 hover:text-slate-400"
            onClick={() => setSidebarOpen(!sidebarOpen)}
            aria-controls="sidebar"
            aria-expanded={sidebarOpen}
          >
            <span className="sr-only">Close sidebar</span>
            <svg
              className="w-6 h-6 fill-current"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M10.7 18.7l1.4-1.4L7.8 13H20v-2H7.8l4.3-4.3-1.4-1.4L4 12z" />
            </svg>
          </button>
          {/* Logo */}
          <NavLink end to="/" className="block">
            <span className="text-xl font-bold text-white">WifiDabba</span>
          </NavLink>
        </div>

        <hr className="my-5 border-gray-500" />

        <div className="space-y-8">
          <div>
            <ul className="mt-3 space-y-2">
              {/* <li
                className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${
                  pathname === "/dashboard" && "bg-slate-900"
                }`}
              >
                <NavLink
                  end
                  to="/dashboard"
                  className={`block text-slate-200 hover:text-white truncate transition duration-150 ${
                    pathname === "/dashboard" &&
                    "hover:text-slate-200 text-indigo-500"
                  }`}
                >
                  <div className="flex items-center">
                    <FontAwesomeIcon
                      className="shrink-0 h-5 w-5"
                      icon={faChartLine}
                    />

                    <span className="text-sm font-medium ml-3 lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                      Dashboard
                    </span>
                  </div>
                </NavLink>
              </li> */}

              <SidebarLinkGroup
                activecondition={pathname.includes("properties")}
              >
                {(handleClick, open) => {
                  return (
                    <React.Fragment>
                      <a
                        href="#0"
                        className={`block text-slate-200 hover:text-white truncate transition duration-150 ${
                          pathname.includes("properties") &&
                          "hover:text-slate-200 text-indigo-500"
                        }`}
                        onClick={(e) => {
                          e.preventDefault();
                          sidebarExpanded
                            ? handleClick()
                            : setSidebarExpanded(true);
                        }}
                      >
                        <div className="flex items-center justify-between">
                          <div className="flex items-center">
                            <FontAwesomeIcon
                              className="shrink-0 h-5 w-5"
                              icon={faBuilding}
                            />

                            <span className="text-sm font-medium ml-3 lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                              Properties
                            </span>
                          </div>
                          {/* Icon */}
                          <div className="flex shrink-0 ml-2">
                            <svg
                              className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 ${
                                open && "transform rotate-180"
                              }`}
                              viewBox="0 0 12 12"
                            >
                              <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                            </svg>
                          </div>
                        </div>
                      </a>
                      <div className="lg:block lg:sidebar-expanded:block 2xl:block">
                        <ul
                          className={`pl-6 mt-4 space-y-3 ${!open && "hidden"}`}
                        >
                          {/* <li className="mb-1 last:mb-0">
                            <NavLink
                              end
                              to="/properties/create"
                              className={`block text-slate-400 hover:text-slate-200 transition duration-150 truncate ${
                                pathname === "/properties/create" &&
                                "text-indigo-400"
                              }`}
                            >
                              <FontAwesomeIcon
                                className="shrink-0 h-4 w-4 mr-2"
                                icon={faBuilding}
                              />

                              <span className="text-sm font-normal lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                Add Property
                              </span>
                            </NavLink>
                          </li> */}

                          <li className="mb-1 last:mb-0">
                            <NavLink
                              end
                              to="/properties"
                              className={`block text-slate-400 hover:text-slate-200 transition duration-150 truncate ${
                                pathname === "/properties" && "text-indigo-400"
                              }`}
                            >
                              <FontAwesomeIcon
                                className="shrink-0 h-4 w-4 mr-2"
                                icon={faCity}
                              />

                              <span className="text-sm font-normal lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                Properties
                              </span>
                            </NavLink>
                          </li>

                          {/* <li className="mb-1 last:mb-0">
                            <NavLink
                              end
                              to="/properties/on-map"
                              className={`block text-slate-400 hover:text-slate-200 transition duration-150 truncate ${
                                pathname === "/properties/on-map" &&
                                "text-indigo-400"
                              }`}
                            >
                              <FontAwesomeIcon
                                className="shrink-0 h-4 w-4 mr-2"
                                icon={faMapLocationDot}
                              />

                              <span className="text-sm font-normal lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                Properties On Map
                              </span>
                            </NavLink>
                          </li> */}
                        </ul>
                      </div>
                    </React.Fragment>
                  );
                }}
              </SidebarLinkGroup>

              {/* <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${pathname === "/pops" && "bg-slate-900"}`}>
                                <NavLink end to="/pops" className={`block text-slate-200 hover:text-white truncate transition duration-150 ${pathname.includes("pops") && "text-indigo-500 hover:text-slate-200"}`}>
                                    <div className="flex items-center">
                                        <FontAwesomeIcon className="shrink-0 h-5 w-5" icon={faTowerCell} />

                                        <span className="text-sm font-medium ml-3 lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">PoPs</span>
                                    </div>
                                </NavLink>
                            </li> */}

              {/* <SidebarLinkGroup activecondition={pathname.includes("payments")}>
                {(handleClick, open) => {
                  return (
                    <React.Fragment>
                      <a
                        href="#0"
                        className={`block text-slate-200 hover:text-white truncate transition duration-150 ${
                          pathname.includes("payments") &&
                          "hover:text-slate-200 text-indigo-500"
                        }`}
                        onClick={(e) => {
                          e.preventDefault();
                          sidebarExpanded
                            ? handleClick()
                            : setSidebarExpanded(true);
                        }}
                      >
                        <div className="flex items-center justify-between">
                          <div className="flex items-center">
                            <FontAwesomeIcon
                              className="shrink-0 h-5 w-5"
                              icon={faReceipt}
                            />

                            <span className="text-sm font-medium ml-3 lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                              Payments
                            </span>
                          </div>
                        
                          <div className="flex shrink-0 ml-2">
                            <svg
                              className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 ${
                                open && "transform rotate-180"
                              }`}
                              viewBox="0 0 12 12"
                            >
                              <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                            </svg>
                          </div>
                        </div>
                      </a>
                      <div className="lg:block lg:sidebar-expanded:block 2xl:block">
                        <ul
                          className={`pl-6 mt-4 space-y-3 ${!open && "hidden"}`}
                        >
                          <li className="mb-1 last:mb-0">
                            <NavLink
                              end
                              to="/payments/plan-recharge"
                              className={`block text-slate-400 hover:text-slate-200 transition duration-150 truncate ${
                                pathname === "/payments/plan-recharge" &&
                                "text-indigo-400"
                              }`}
                            >
                              <FontAwesomeIcon
                                className="shrink-0 h-4 w-4 mr-2"
                                icon={faBolt}
                              />

                              <span className="text-sm font-normal lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                Plans Recharge
                              </span>
                            </NavLink>
                          </li>
                        </ul>
                      </div>
                    </React.Fragment>
                  );
                }}
              </SidebarLinkGroup> */}

              <SidebarLinkGroup
                activecondition={pathname.includes("customers")}
              >
                {(handleClick, open) => {
                  return (
                    <React.Fragment>
                      <a
                        href="#0"
                        className={`block text-slate-200 hover:text-white truncate transition duration-150 ${
                          pathname.includes("customers") &&
                          "hover:text-slate-200 text-indigo-500"
                        }`}
                        onClick={(e) => {
                          e.preventDefault();
                          sidebarExpanded
                            ? handleClick()
                            : setSidebarExpanded(true);
                        }}
                      >
                        <div className="flex items-center justify-between">
                          <div className="flex items-center">
                            <FontAwesomeIcon
                              className="shrink-0 h-6 w-6"
                              icon={faPeopleGroup}
                            />
                            <span className="text-sm font-medium ml-3 lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                              Customers
                            </span>
                          </div>
                          {/* Icon */}
                          <div className="flex shrink-0 ml-2">
                            <svg
                              className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 ${
                                open && "transform rotate-180"
                              }`}
                              viewBox="0 0 12 12"
                            >
                              <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                            </svg>
                          </div>
                        </div>
                      </a>
                      <div className="lg:block lg:sidebar-expanded:block 2xl:block">
                        <ul
                          className={`pl-6 mt-4 space-y-3 ${!open && "hidden"}`}
                        >
                          <li className="mb-1 last:mb-0">
                            <NavLink
                              end
                              to="/customers/property-customers"
                              className={`block text-slate-400 hover:text-slate-200 transition duration-150 truncate  ${
                                pathname === "/customers/property-customers" &&
                                "text-indigo-400"
                              }`}
                            >
                              <FontAwesomeIcon
                                className="shrink-0 h-5 w-5 mr-2"
                                icon={faPeopleGroup}
                              />

                              <span className="text-sm font-normal lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                All Users
                              </span>
                            </NavLink>
                          </li>
                          <li className="mb-1 last:mb-0">
                            <NavLink
                              end
                              to="/customers/create-user"
                              className={`block text-slate-400 hover:text-slate-200 transition duration-150 truncate  ${
                                pathname === "/customers/create-user" &&
                                "text-indigo-400"
                              }`}
                            >
                              <FontAwesomeIcon
                                className="shrink-0 h-5 w-5 mr-2"
                                icon={faPerson}
                              />

                              <span className="text-sm font-normal lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                Create User
                              </span>
                            </NavLink>
                          </li>

                          <li className="mb-1 last:mb-0">
                            <NavLink
                              end
                              to="/customers/remove-user"
                              className={`block text-slate-400 hover:text-slate-200 transition duration-150 truncate ${
                                pathname === "/customers/remove-user" &&
                                "text-indigo-400"
                              }`}
                            >
                              <FontAwesomeIcon
                                className="shrink-0 h-5 w-5 mr-2"
                                icon={faPersonCircleXmark}
                              />

                              <span className="text-sm font-normal lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                Remove User
                              </span>
                            </NavLink>
                          </li>
                          <li className="mb-1 last:mb-0">
                            <NavLink
                              end
                              to="/customers/plan-details"
                              className={`block text-slate-400 hover:text-slate-200 transition duration-150 truncate ${
                                (pathname === "/customers/plan-details" ||
                                  pathname.search(
                                    "customers/bandwidth-consumption-details"
                                  ) !== -1) &&
                                "text-indigo-400"
                              }`}
                            >
                              <FontAwesomeIcon
                                className="shrink-0 h-4 w-4 mr-2"
                                icon={faIdBadge}
                              />

                              <span className="text-sm font-normal lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                User Plan Details
                              </span>
                            </NavLink>
                          </li>
                          <li className="mb-1 last:mb-0">
                            <NavLink
                              end
                              to="/customers/add-user-device"
                              className={`block text-slate-400 hover:text-slate-200 transition duration-150 truncate ${
                                pathname === "/customers/add-user-device" &&
                                "text-indigo-400"
                              }`}
                            >
                              <FontAwesomeIcon
                                className="shrink-0 h-4 w-4 mr-2"
                                icon={faMobileScreenButton}
                              />

                              <span className="text-sm font-normal lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                Add User Device
                              </span>
                            </NavLink>
                          </li>
                          <li className="mb-1 last:mb-0">
                            <NavLink
                              end
                              to="/customers/temporary-block-user"
                              className={`block text-slate-400 hover:text-slate-200 transition duration-150 truncate ${
                                pathname ===
                                  "/customers/temporary-block-user" &&
                                "text-indigo-400"
                              }`}
                            >
                              <FontAwesomeIcon
                                className="shrink-0 h-4 w-4 mr-2"
                                icon={faXmark}
                              />

                              <span className="text-sm font-normal lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                Temporary Block Users
                              </span>
                            </NavLink>
                          </li>
                          <li className="mb-1 last:mb-0">
                            <NavLink
                              end
                              to="/customers/temporary-unblock-user"
                              className={`block text-slate-400 hover:text-slate-200 transition duration-150 truncate ${
                                pathname ===
                                  "/customers/temporary-unblock-user" &&
                                "text-indigo-400"
                              }`}
                            >
                              <FontAwesomeIcon
                                className="shrink-0 h-4 w-4 mr-2"
                                icon={faCheck}
                              />

                              <span className="text-sm font-normal lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                Temporary UnBlock Users
                              </span>
                            </NavLink>
                          </li>
                        </ul>
                      </div>
                    </React.Fragment>
                  );
                }}
              </SidebarLinkGroup>

              {/* <SidebarLinkGroup
                activecondition={pathname.includes("customer-support")}
              >
                {(handleClick, open) => {
                  return (
                    <React.Fragment>
                      <a
                        href="#0"
                        className={`block text-slate-200 hover:text-white truncate transition duration-150 ${
                          pathname.includes("customer-support") &&
                          "hover:text-slate-200 text-indigo-500"
                        }`}
                        onClick={(e) => {
                          e.preventDefault();
                          sidebarExpanded
                            ? handleClick()
                            : setSidebarExpanded(true);
                        }}
                      >
                        <div className="flex items-center justify-between">
                          <div className="flex items-center">
                            <FontAwesomeIcon
                              className="shrink-0 h-5 w-5"
                              icon={faHeadset}
                            />
                            <span className="text-sm font-medium ml-3 lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                              Customer Support
                            </span>
                          </div>
                          
                          <div className="flex shrink-0 ml-2">
                            <svg
                              className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 ${
                                open && "transform rotate-180"
                              }`}
                              viewBox="0 0 12 12"
                            >
                              <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                            </svg>
                          </div>
                        </div>
                      </a>
                      <div className="lg:block lg:sidebar-expanded:block 2xl:block">
                        <ul
                          className={`pl-6 mt-4 space-y-3 ${!open && "hidden"}`}
                        >
                          <li className="mb-1 last:mb-0">
                            <NavLink
                              end
                              to="/customer-support/sms-notification"
                              className={`block text-slate-400 hover:text-slate-200 transition duration-150 truncate ${
                                pathname ===
                                  "/customer-support/sms-notification" &&
                                "text-indigo-400"
                              }`}
                            >
                              <FontAwesomeIcon
                                className="shrink-0 h-4 w-4 mr-2"
                                icon={faMessage}
                              />

                              <span className="text-sm font-normal lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                SMS Notifications
                              </span>
                            </NavLink>
                          </li>
                        </ul>
                      </div>
                    </React.Fragment>
                  );
                }}
              </SidebarLinkGroup> */}
            </ul>
          </div>
        </div>

        {/* Expand / collapse button */}
        <div className="pt-3 hidden lg:inline-flex 2xl:hidden justify-end mt-auto">
          <div className="px-3 py-2">
            <button onClick={() => setSidebarExpanded(!sidebarExpanded)}>
              <span className="sr-only">Expand / collapse sidebar</span>
              <svg
                className="w-6 h-6 fill-current sidebar-expanded:rotate-180"
                viewBox="0 0 24 24"
              >
                <path
                  className="text-slate-400"
                  d="M19.586 11l-5-5L16 4.586 23.414 12 16 19.414 14.586 18l5-5H7v-2z"
                />
                <path className="text-slate-600" d="M3 23H1V1h2z" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
