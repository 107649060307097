import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import DashboardLayout from "../../layouts/DashboardLayout";
import { partners } from "../../services/partners/PartnerService";
import { propertiesList } from "../../services/properties/PropertyService";
import { toast } from "react-toastify";
import searchPropertiesValidation from "../../validations/properties/searchPropertiesValidation";
import axios from "../../helpers/axios";
import { Link } from "react-router-dom";
function UserDetails() {
  const [partnersList, setPartnersList] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [properties, setProperties] = useState([]);
  const getPartnersList = async () => {
    try {
      const res = await (await axios.get("api/partners")).data;
      if (res.status === "success") {
        setPartnersList(res.data.partners);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getProperties = async () => {
    try {
      const res = await (
        await axios.get(`/api/dabbas?hasAccessPoints=false`)
      ).data;

      if (res.status === "success") {
        setProperties(res.data.dabbas);
      } else {
        console.log(error);
      }
    } catch (error) {
      console.log(error.response.data.message);
      toast.error(error.response.data.message);
    }
  };

  const getPropertyCustomers = async (proprtyId) => {
    try {
      const res = await (
        await axios.get(`/api/users?dabbaId=${proprtyId}`)
      ).data;

      if (res.status === "success") {
       
        setCustomers(res.data.users);
      } else {
        console.log(error);
      }
    } catch (error) {
      console.log();
      toast.error(error.response.data.message);
    }
  };

  useEffect(() => {
    getProperties();
    if (properties.length) {
      getPropertyCustomers(properties[0]._id);
    }
  }, [properties.length]);

  return (
    <Formik
      initialValues={{
        property_id: properties.length? properties[0]._id: 0,
      }}
      validationSchema={searchPropertiesValidation}
    >
      {({ setFieldValue }) => (
        <DashboardLayout>
          <div className="flex justify-between">
            <h1 className="font-bold text-3xl">Property wise customers</h1>

            <Form className="space-x-3">
              <div>
                <label htmlFor="property_id">Property *</label>

                <ErrorMessage
                  name="property_id"
                  component="div"
                  className="text-red-500"
                />

                <Field
                  as="select"
                  name="property_id"
                  id="property_id"
                  onChange={(event) => {
                    setFieldValue("property_id", event.target.value);
                    getPropertyCustomers(event.target.value);
                  }}
                >
        
                  {properties.length
                    ? properties.map((property) => {
                        return (
                          <option
                            data-partner-code={property.partner.code}
                            value={property._id}
                            key={property._id}
                          >
                            {property.property_name}
                          </option>
                        );
                      })
                    : <option value="">Select Partner</option>}
                </Field>
              </div>
            </Form>
          </div>
          <div className="border-gray-500 shadow rounded-sm bg-white p-5 mt-6 overflow-x-scroll">
            <table className="table table-border table-hover">
              <thead>
                <tr className="bg-gray-100">
                  <th>Phone Number</th>
                  <th>Has Plan</th>
                  <th>Plan Details</th>
                  <th>Data Consumed</th>
                  <th>Mac Addresses</th>
                </tr>
              </thead>
              <tbody>
                {customers.length
                  ? customers.map((customer) => {
                      return (
                        <tr key={customer._id}>
                          <td>
                            <Link
                              className="text-indigo-600 font-semibold pl-2"
                              to={`/customers/plan-details?phone=${customer.phone}`}
                            >
                              {customer.phone}
                            </Link>
                          </td>
                          <td>
                            {customer && customer.current_plan ? (
                              <p>
                                {Object.keys(customer?.current_plan).length
                                  ? "Yes"
                                  : "No"}
                              </p>
                            ) : (
                              "No"
                            )}
                          </td>

                          <td>
                            <p>
                              {customer?.current_plan
                                ?.bandwidth_purchased_in_bytes
                                ? `${
                                    customer?.current_plan
                                      ?.bandwidth_purchased_in_bytes /
                                    (1000 * 1000 * 1000)
                                  }GB (Current)`
                                : null}
                            </p>
                            {customer?.upcoming_plans?.length
                              ? customer.upcoming_plans.map((item, index) => {
                                  return (
                                    <p key={index}>
                                      {item?.bandwidth_purchased_in_bytes /
                                        (1000 * 1000 * 1000)}
                                      GB
                                    </p>
                                  );
                                })
                              : null}
                          </td>
                          <td>
                            {customer && customer.current_plan ? (
                              <p>
                                {(
                                  customer.current_plan
                                    .bandwidth_consumed_in_bytes /
                                  (1000 * 1000)
                                ).toFixed(2)}{" "}
                                MB
                              </p>
                            ) : (
                              "N/A"
                            )}
                          </td>
                          <td>
                            {customer.devices.map((address) => (
                              <p key={address}>{address}</p>
                            ))}
                          </td>
                        </tr>
                      );
                    })
                  : null}
              </tbody>
            </table>
          </div>
        </DashboardLayout>
      )}
    </Formik>
  );
}

export default UserDetails;
