import axios from "axios";

const instance = axios.create({
  baseURL: "https://b2b-api.wifidabba.com",
});

instance.interceptors.request.use(
  function (config) {
    const token = window.localStorage.getItem("partner-token");
    // Do something before request is sent

    config.headers["x-frontend-identifier"] = "partners.wifidabba.com";

    if (token) {
      config.headers.authorization = `Bearer ${token}`;
    }

    // if (config.url.includes('login' || "/signup" || ))

    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// instance.interceptors.response.use(
//   (response) => {
//     if (response.status === 401) {
//       localStorage.removeItem("partner-token")
//       throw new Error({response: {data: {message: "Authorization revoked"}}})
//     }
//     return response;
//   }, (error) => {
//     return Promise.reject(error)
//   }
// )

export default instance;
