function LoginLayout(props) {
    return (
        <div className="h-screen overflow-auto bg-gray-100 px-3">
            <div className="w-full sm:w-2/4 md:w-2/4 lg:w-1/4 mx-auto pt-20">
                <div className="rounded shadow-md p-5 bg-white">
                    <div className="text-3xl text-center font-semibold">WifiDabba Tools</div>
                    
                    {props.children}

                    <div className="mt-5 text-center">
                        <p>
                            <span className="text-gray-500 mr-2">Need help?</span>

                            <a href="mailto:chan@gmail.com" className="text-indigo-700">
                                Account support
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LoginLayout;
