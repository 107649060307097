import axios from "axios";
import Cookies from "js-cookie";
import ApiUrl from "../../config/ApiUrl";

const apiToken = Cookies.get("apiToken", {path: "/"});

async function addUserDevice (formFields)
{
    return await axios.post(ApiUrl.customers.addUserDevice, formFields, {
        headers: {
            "X-Authorization": apiToken
        }
    });
}

async function logoutAllDevices (phone)
{
    return await axios.post(ApiUrl.customers.logoutAllDevice, {
        phone
    }, {
        headers: {
            "X-Authorization": apiToken
        }
    });
}

async function activateUserDevice (formFields)
{
    return await axios.post(ApiUrl.customers.activateUserDevice, formFields, {
        headers: {
            "X-Authorization": apiToken
        }
    });
}

async function deactivateUserDevice (formFields)
{
    return await axios.post(ApiUrl.customers.deActivateUserDevice, formFields, {
        headers: {
            "X-Authorization": apiToken
        }
    });
}

export {
    addUserDevice,
    logoutAllDevices,
    activateUserDevice,
    deactivateUserDevice
};
