import * as yup from "yup";


const SMSNotificationValidation = yup.object().shape({
    partner_id: yup.string().required("Partner is required."),
    business_dabba_ids: yup.array().min(1, "Please select atlease one property."),
    sms_template: yup.string().required("SMS template is required.")
        .oneOf([
            "Hello. Your Wifi may be experiencing issues. Our team is working on it. We will update you when the issue is resolved.",
            "Hi, Wifi services are live and running. Sorry for the inconvenience caused."
        ], "Paste correct template.")
});

export default SMSNotificationValidation;
