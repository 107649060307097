import { Link } from "react-router-dom";

function PlanDetails({ plan, planTitle }) {
  return (
    <div className="col-span-12 flex flex-col border-gray-500 shadow rounded-sm bg-white p-5">
      <h2 className="text-xl text-gray-600">{planTitle}</h2>
      <hr className="my-4" />

      <div className="overflow-x-scroll">
        <table className="table table-border table-hover">
          <thead>
            <tr>
              <td>B/W Purchased (GB)</td>
              <td>Plan Type</td>
              <td>Start Validity</td>
              <td>End Validity</td>
              <td>Price</td>
              <td>Is Expired</td>
              <td>Action</td>
            </tr>
          </thead>
          <tbody className="text-sm">
            {plan.map((dabbaUserPlan) => {
              return (
                <tr key={dabbaUserPlan._id} className="hover:bg-gray-100">
                  <td>
                    {dabbaUserPlan?.bandwidth_purchased_in_bytes > 0
                      ? (
                          dabbaUserPlan?.bandwidth_purchased_in_bytes /
                          (1000 * 1000 * 1000)
                        ).toFixed(2)
                      : 0}
                  </td>
                  <td>{dabbaUserPlan && dabbaUserPlan?.transaction_type}</td>
                  <td>
                    {new Date(dabbaUserPlan.validity_started_on).toUTCString()}
                  </td>
                  <td>
                    {new Date(dabbaUserPlan.validity_ended_on).toUTCString()}
                  </td>
                  <td>{dabbaUserPlan.price.$numberDecimal}</td>
                  <td>{dabbaUserPlan.deleted_at ? "YES" : "NO"}</td>
                  <td>
                    <Link
                      target="_blank"
                      rel="noreferrer"
                      to={`/customers/bandwidth-consumption-details/${dabbaUserPlan._id}`}
                      className="text-indigo-600 font-medium"
                    >
                      <span className="glyphicon glyphicon-flash"></span> Usage
                      Details
                    </Link>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default PlanDetails;
