import React, { useEffect } from "react";
import RouteLinks from "./router/RouteLinks";
import { clearLocalStorageDaily } from "./helpers/LocalStorageHelper";

if (["production"].includes(process.env.REACT_APP_ENV)) {
  import("./utils/instrumentation");
}

export default function App() {
  return (
    <div>
      <RouteLinks />
    </div>
  );
}
