import * as yup from "yup";

const createPropertyValidation = yup.object().shape({
  partner_id: yup.string().required("Partner field is required."),
  custom_plan_id: yup.string().required("Property bandwidth plan is required."),
  partner_location_id: yup
    .number()
    .typeError("Partner location must be numeric.")
    .nullable(true),
  business_name: yup.string().required("Property name is required."),
  owner_name: yup.string().required("Property owner name is required."),
  phone: yup.string().required("Property owner phone is required."),
  landmark: yup.string().required("Property landmark is required."),
  address: yup.string().required("Address is required."),
  locality: yup.string().required("Locality is required."),
  postal_code: yup.string().required("Postal code is required"),
  wd_number: yup.string().nullable(true),
  totalBaseDabbas: yup.number().required("Total base dabbas are required"),
  totalAccessPointDabbas: yup
    .number()
    .required("Total accesspoint dabbas are required"),
  tenants: yup.number().required("Number of tenants are required"),
  dabba_provisioning_status: yup.string().required("Status is required"),
  dabba_model: yup.string().required("Dabba model is required"),
  lat: yup.string().required("Lattitude is required"),
  lng: yup.string().required("Longitude model is required"),
});

export default createPropertyValidation;
