import { ErrorMessage, Field, Form, Formik } from "formik";
import DashboardLayout from "../../layouts/DashboardLayout";
import { toast } from "react-toastify";
import addUserDeviceValidation from "../../validations/customers/addUserDeviceValidation";
import axios from "../../helpers/axios";

const formatText = (text) => {
  // Remove non-alphanumeric characters
  const alphanumericText = text.replace(/[^0-9A-Za-z]/g, "");

  // Add colons after every two letters
  const formattedText = alphanumericText.replace(/(.{2})/g, "$1:");

  // Remove trailing colon if there are an odd number of characters
  return formattedText.replace(/:$/, "");
};

function AddUserDevice() {
  const addUserDevice = async (values, resetForm) => {
    try {
      const res = await (
        await axios.post("/api/users/devices", {
          phone: values.phone,
          macAddress: values.mac_address,
          notes: values.notes ? values.notes : null,
        })
      ).data;
      if (res.status === "success") {
        toast.success(res.data.message);
        resetForm({ values: "" });
      }
    } catch (error) {
      console.log(error.response.data.message);
      toast.error(error.response.data.message);
      resetForm({ values: "" });
    }
  };

  return (
    <Formik
      initialValues={{
        phone: "",
        mac_address: "",
        notes: "",
      }}
      validationSchema={addUserDeviceValidation}
      onSubmit={(values, { resetForm }) => {
        addUserDevice(values, resetForm);
      }}
    >
      {(props) => {
        return (
          <DashboardLayout>
            <div className="flex justify-between align-middle">
              <h1 className="font-bold text-3xl">Add User Device</h1>
            </div>
            <div className="border-gray-500 shadow rounded-sm bg-white p-5 mt-6">
              <div className="grid grid-cols-12 gap-x-4">
                <Form
                  onSubmit={props.handleSubmit}
                  className="space-y-4 col-span-12 md:col-span-6 lg:col-span-4"
                >
                  <div>
                    <label htmlFor="phone">Phone Number *</label>

                    <ErrorMessage
                      name="phone"
                      component="div"
                      className="text-red-500"
                    />

                    <Field type="text" name="phone" id="phone" />
                  </div>

                  <div>
                    <label htmlFor="mac_address">Device MAC-Address *</label>

                    <ErrorMessage
                      name="mac_address"
                      component="div"
                      className="text-red-500"
                    />

                    <Field
                      value={formatText(props.values.mac_address)}
                      type="text"
                      max={17}
                      name="mac_address"
                      id="mac_address"
                    />
                  </div>

                  <div>
                    <label htmlFor="notes">
                      Notes{" "}
                      <span className="text-xs">
                        (Add property name of partner)
                      </span>
                    </label>

                    <ErrorMessage
                      name="notes"
                      component="div"
                      className="text-red-500"
                    />

                    <Field type="text" name="notes" id="notes" />
                  </div>

                  <div className="pt-2">
                    <button type="submit" className="btn btn-primary">
                      Add User Device
                    </button>
                  </div>
                </Form>
              </div>
            </div>
          </DashboardLayout>
        );
      }}
    </Formik>
  );
}

export default AddUserDevice;
