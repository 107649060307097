import { ErrorMessage, Field, Form, Formik } from "formik";
import { Link } from "react-router-dom";
import DashboardLayout from "../../layouts/DashboardLayout";
import { useEffect, useState } from "react";
import { partnerWiseProperties } from "../../services/properties/PropertyService";
import { registerUser } from "../../services/customers/CustomerService";
import { toast } from "react-toastify";
import createUserValidation from "../../validations/customers/createUserValidation";
import axios from "../../helpers/axios";
function CreateUser() {
  const [partnerPropertiesList, setPartnerPropertiesList] = useState([]);

  const getProperties = async () => {
    try {
      const res = await (
        await axios.get(`/api/dabbas?hasAccessPoints=false`)
      ).data;

      if (res.status === "success") {
        setPartnerPropertiesList(res.data.dabbas);
      } else {
        console.log(error);
      }
    } catch (error) {
      console.log(error.response);
      toast.error(error.response.data.message);
    }
  };

  const createUser = async (values) => {
    try {
      const res = await (
        await axios.post("/api/users", {
          dabbaId: values.dabba_id || null,
          phone: values.phone || null,
          macAddress: values.mac_address || null,
          byPassPartnerValidation: false,
        })
      ).data;
      if (res.status === "success") {
        toast.success("User created succesfully!");
      } else {
        throw new Error("Something went wrong");
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  useEffect(() => {
    getProperties();
  }, []);

  return (
    <Formik
      initialValues={{
        dabba_id: "",
        phone: "",
        mac_address: "",
        is_property_manager: false,
        partner_code: partnerPropertiesList.length ? partnerPropertiesList[0]._id : "",
      }}
      validationSchema={createUserValidation}
      onSubmit={(values) => {
        createUser(values);
      }}
    >
      {(props) => (
        <DashboardLayout>
          <div className="flex justify-between align-middle">
            <h1 className="font-bold text-3xl">Create User</h1>
            <Link to="/customers/create" className="btn btn-primary">
              Add User
            </Link>
          </div>
          <div className="border-gray-500 shadow rounded-sm bg-white p-5 mt-6">
            <Form className="space-y-4">
              <div className="md:w-1/3">
                <label htmlFor="dabba_id">Property *</label>

                <ErrorMessage
                  name="dabba_id"
                  component="div"
                  className="text-red-500"
                />

                <Field
                  as="select"
                  name="dabba_id"
                  id="dabba_id"
                  onChange={(event) => {
                    props.setFieldValue("dabba_id", event.target.value);
                    const location = document.getElementById("dabba_id");
                    props.setFieldValue(
                      "partner_code",
                      location.options[location.selectedIndex].getAttribute(
                        "data-partner-code"
                      )
                    );
                  }}
                >
                 

                  {partnerPropertiesList.length
                    ? partnerPropertiesList.map((property) => {
                        return (
                          <option
                            data-partner-code={property.partner.code}
                            value={property._id}
                            key={property._id}
                          >
                            {property.property_name}
                          </option>
                        );
                      })
                    :  <option value="">Select Property</option>}
                </Field>
              </div>

              <div className="md:w-1/3">
                <label htmlFor="phone">User Phone Number *</label>

                <ErrorMessage
                  name="phone"
                  component="div"
                  className="text-red-500"
                />

                <Field type="text" name="phone" id="phone" />
              </div>

              <div className="md:w-1/3">
                <label htmlFor="mac_address">User Device MAC-Address</label>

                <ErrorMessage
                  name="mac_address"
                  component="div"
                  className="text-red-500"
                />

                <Field type="text" name="mac_address" id="mac_address" />
              </div>

              <div className="md:w-1/3">
                <ErrorMessage
                  name="is_property_manager"
                  component="div"
                  className="text-red-500"
                />

                <label
                  htmlFor="is_property_manager"
                  className="flex items-center"
                >
                  <Field
                    type="checkbox"
                    name="is_property_manager"
                    id="is_property_manager"
                    className="mr-2"
                  />
                  Is Property Manager *
                </label>
                <div className="text-xs text-gray-500">
                  [Check this only if the user is property manager]
                </div>
              </div>

              <div>
                <button type="submit" className="btn btn-primary">
                  Create User
                </button>
              </div>
            </Form>
          </div>
        </DashboardLayout>
      )}
    </Formik>
  );
}

export default CreateUser;
