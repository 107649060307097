import { Navigate } from "react-router";
import Login from "../pages/auth/Login";

const isAuthenticated = () => {
  // Your authentication logic here
  return !!localStorage.getItem("partner-token"); // Replace with your actual authentication logic
};

const ProtectedRoute = ({ element }) => {
  return isAuthenticated() ? (
    element
  ) : (
    <Navigate
      to="/"
      replace={true}
      state={{ from: window.location.pathname }}
    />
  );
};

export default ProtectedRoute;
