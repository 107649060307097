import { ErrorMessage, Field, Form, Formik } from "formik";
import DashboardLayout from "../../layouts/DashboardLayout";
import { useEffect, useState } from "react";
import { customPlans } from "../../services/properties/PropertyService";
import { createCustomerPlanRecharge } from "../../services/payments/RechargeService";
import { toast } from "react-toastify";
import planRechargeValidation from "../../validations/payments/planRechargeValidation";
import axios from "../../helpers/axios";
function PlanRecharge() {
  const [rechargePlansList, setRechargePlansList] = useState([]);

  useEffect(() => {
    getPlans();
  }, []);

  const recharge = async (values, resetForm) => {
    try {
      const res = await (
        await axios.post("/api/payments/failed-payment-recharge", {
          bandwidthPlanId: values.dabba_custom_plan_id,
          phone: values.phone,
          topUpType: values.topup_type,
        })
      ).data;
      if (res.status === "success") {
        toast.success(
          `${res.data.payment.status} ${
            res.data.userPlan.bandwidth_purchased_in_bytes /
            (1000 * 1000 * 1000)
          } GB`
        );
        resetForm({ values: "" });
      } else {
        throw new Error("Something went wrong");
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  async function getPlans() {
    try {
      const res = await (
        await axios.get("/api/bandwidth-plans?hasListingPlans=true")
      ).data;
      if (res.status === "success") {
        setRechargePlansList(res.data.bandwidthPlans);
      } else {
        throw new Error("Somethiong went wrong!");
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Formik
      initialValues={{
        phone: "",
        dabba_custom_plan_id: "",
        topup_type: "COMPLIMENTARY",
      }}
      validationSchema={planRechargeValidation}
      onSubmit={async (values, { resetForm }) => {
        recharge(values, resetForm);
      }}
    >
      {(props) => (
        <DashboardLayout>
          <div className="flex justify-between align-middle">
            <h1 className="font-bold text-3xl">Plan Recharge</h1>
          </div>

          <div className="col-span-12 border-gray-500 shadow rounded-sm bg-white p-5 mt-6">
            <Form onSubmit={props.handleSubmit} className="space-y-4">
              <div className="md:w-1/3">
                <label htmlFor="phone">Phone Number *</label>

                <ErrorMessage
                  name="phone"
                  component="div"
                  className="text-red-500"
                />

                <Field type="text" name="phone" id="phone" />
              </div>

              <div className="md:w-1/3">
                <label htmlFor="dabba_custom_plan_id">Recharge Plans *</label>

                <ErrorMessage
                  name="dabba_custom_plan_id"
                  component="div"
                  className="text-red-500"
                />

                <Field as="select" name="dabba_custom_plan_id" id="_id">
                  <option value="">Select Plan</option>
                  {rechargePlansList.length &&
                    rechargePlansList.map((rechargePlan) => {
                      return (
                        <option value={rechargePlan._id} key={rechargePlan._id}>
                          {rechargePlan.name} |{" "}
                          {rechargePlan.price.$numberDecimal}
                        </option>
                      );
                    })}
                </Field>
              </div>

              <div className="md:w-1/3">
                <label>TopUp Type *</label>

                <ErrorMessage
                  name="topup_type"
                  component="div"
                  className="text-red-500"
                />

                <div className="space-x-3 flex py-1">
                  <label className="flex items-center">
                    <Field
                      type="radio"
                      name="topup_type"
                      value="COMPLIMENTARY"
                      className="mr-1"
                    ></Field>
                    Complimentary
                  </label>

                  <label className="flex items-center">
                    <Field
                      type="radio"
                      name="topup_type"
                      value="TOPUP"
                      className="mr-1"
                    ></Field>
                    TopUp
                  </label>
                </div>
              </div>

              <div>
                <button type="submit" className="btn btn-primary">
                  Add Plan
                </button>
              </div>
            </Form>
          </div>
        </DashboardLayout>
      )}
    </Formik>
  );
}

export default PlanRecharge;
