import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { requestOtp, verifyOtp } from "../../services/auth/LoginService";
import axios from "../../helpers/axios";
import { ErrorMessage, Field, Formik, Form } from "formik";
import ReCAPTCHA from "react-google-recaptcha";
import LoginLayout from "../../layouts/LoginLayout";
import { toast } from "react-toastify";
import {
  requestOtpValidation,
  verifyOtpValidation,
} from "../../validations/auth/authValidation";
import { useEffect, useRef, useState } from "react";
import parseJwt from "../../helpers/token-parser";

function Login() {
  /** Navigation */
  const navigate = useNavigate();

  const mountedRef = useRef(true);

  /** State */
  const [isLoading, setIsLoading] = useState(false);
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [otpReference, setOtpReference] = useState(null);
  const [verifyGoogleRecaptcha, setVerifyGoogleRecaptcha] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("partner-token")) {
      navigate("/dashboard");
    }

    return () => {
      mountedRef.current = false;
    };
  }, []);

  async function handleRequestOtp(phone) {
    setIsLoading(true);

    try {
      const res = await (
        await axios.post("api/auth/request-otp", { phone })
      ).data;
      if (res.status === "success") {
        setIsOtpSent(true);
        setIsLoading(false);
      } else {
        throw new Error("Something went wrong!");
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  }

  async function handleVerifyOtp(otp, phone, setFieldError) {
    setIsLoading(true);

    try {
      const res = await await axios.post("/api/auth/verify-otp", {
        phone,
        otp,
      });

      if (res.data.status === "success") {
        if (res.headers.authorization) {
          if (parseJwt(res.headers.authorization).roles.includes("PARTNER")) {
          localStorage.setItem("partner-token", res.headers.authorization);
  
          localStorage.setItem("phone-number", res.data.data.phone)
          navigate("/properties");
          } else {
            setIsLoading(false);
            toast.error("Un-authorized user, access denied.");
            return;
          }
        }
        toast.success(res.data.message);
        setIsLoading(false);
      } else {
        throw new Error("Something went wrong!");
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
      setIsLoading(false);
      
    }
  }

  return (
    <Formik
      initialValues={{
        phone: "",
        otp: "",
      }}
      validationSchema={!isOtpSent ? requestOtpValidation : verifyOtpValidation}
      onSubmit={(values, { setFieldError }) => {
        if (!isOtpSent) {
          handleRequestOtp(values.phone);
        } else {
          handleVerifyOtp(values.otp, values.phone, setFieldError);
        }
      }}
    >
      {(props) => (
        <LoginLayout>
          <div>
            <div className="text-center text-xl">Sign in via OTP</div>

            <div className="bg-indigo-200 p-3 mt-10 text-indigo-600 rounded-sm text-center">
              OTP will be sent to your phone
            </div>

            <Form onSubmit={props.handleSubmit}>
              <div className="mt-5">
                <label>Phone number</label>

                <ErrorMessage
                  name="phone"
                  component="div"
                  className="text-red-500"
                />

                <Field
                  type="text"
                  name="phone"
                  id="phone"
                  placeholder="Enter phone"
                  className={`w-full mt-2 ${
                    props.touched.phone && props.errors.phone && "is-invalid"
                  }`}
                />
              </div>

              {isLoading && (
                <button
                  className="btn w-full inline-flex items-center mt-4 justify-center btn-primary-disabled"
                  type="button"
                  disabled
                >
                  <svg
                    className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  {props.btnText ?? "Processing..."}
                </button>
              )}

              {isOtpSent && !isLoading && (
                <>
                  <div className="mt-3">
                    <label>OTP</label>

                    <ErrorMessage
                      name="otp"
                      component="div"
                      className="text-red-500"
                    />

                    <Field
                      type="text"
                      name="otp"
                      placeholder="Enter otp"
                      className={`w-full mt-2 ${
                        props.touched.otp && props.errors.otp && "is-invalid"
                      }`}
                    />
                  </div>

                  <div className="mt-4">
                    <button type="submit" className="w-full btn btn-primary">
                      <i></i> Sign in
                    </button>
                  </div>
                </>
              )}

              {!isOtpSent && !isLoading && (
                <div>
                  <button type="submit" className="btn w-full btn-primary mt-4">
                    <i className="d-none"></i> Request OTP
                  </button>
                </div>
              )}
            </Form>
          </div>
        </LoginLayout>
      )}
    </Formik>
  );
}

export default Login;
