import axios from "axios";
import ApiUrl from "../../config/ApiUrl";
import Cookies from "js-cookie";

const apiToken = Cookies.get("apiToken", {path: "/"});

async function partners()
{
    let partners = null;

    if(localStorage.getItem("partners-list") === null) {
        partners = await axios.get(ApiUrl.partners, {
            headers: {
                "X-Authorization": apiToken
            }
        });

        localStorage.setItem("partners-list", JSON.stringify(partners));
    }

    return JSON.parse(localStorage.getItem("partners-list"));
}

export {
    partners
};
