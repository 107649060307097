import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useState } from "react";
import DashboardLayout from "../../layouts/DashboardLayout";
import { toast } from "react-toastify";
import { temporaryUnBlockUser } from "../../services/customers/BlockUnblockService";
import temporaryUnBlockUserValidation from "../../validations/customers/temporaryUnBlockUserValidation";
import axios from "../../helpers/axios";
function TemporaryUnBlockUser() {
  const [unBlockedUsers, setUnBlockedUsers] = useState([]);
  const [notValidPhoneNumbers, setNotValidPhoneNumbers] = useState([]);
  const [notValidUsers, setNotValidUsers] = useState([]);

  const unblockUserTemporarily = async (values) => {
    try {
      const res = await (
        await axios.post("/api/block-users/temporary-unblock", {
          phoneNumbers: values.phone_numbers || null,
        })
      ).data;
      if (res.status === "success") {
        toast.success(res.message);
        if (res.data.validPhoneNumbers.length) {
          setUnBlockedUsers(res.data.validPhoneNumbers);
        }
        if (res.data.invalidPhoneNumbers.length) {
          setNotValidPhoneNumbers(res.data.invalidPhoneNumbers);
        }
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
  };

  return (
    <Formik
      initialValues={{
        phone_numbers: "",
      }}
      validationSchema={temporaryUnBlockUserValidation}
      onSubmit={(values, { resetForm }) => {
        unblockUserTemporarily(values);
      }}
    >
      {(props) => (
        <DashboardLayout>
          <div className="flex justify-between align-middle">
            <h1 className="font-bold text-3xl">Temporary UnBlock Users</h1>
          </div>
          <div className="col-span-12 border-gray-500 shadow rounded-sm bg-white p-5 mt-6">
            {unBlockedUsers.length ? (
              <div className="bg-indigo-200 text-indigo-600 p-5 rounded-sm mb-3 text-sm">
                <span className="font-semibold">UnBlocked users: </span>
                {unBlockedUsers}
              </div>
            ) : null}

            {notValidPhoneNumbers.length ? (
              <div className="bg-red-200 text-red-600 p-5 rounded-sm mb-3 text-sm">
                <span className="font-semibold">Non valid phone number: </span>
                {notValidPhoneNumbers}
              </div>
            ) : null}

            {notValidUsers.length ? (
              <div className="bg-red-200 text-red-600 p-5 rounded-sm mb-3 text-sm">
                <span className="font-semibold">Non valid users: </span>
                {notValidUsers}
              </div>
            ) : null}

            <Form onSubmit={props.handleSubmit} className="space-y-4">
              <div className="md:w-1/3">
                <label htmlFor="phone_numbers">Phone Number *</label>

                <ErrorMessage
                  name="phone_numbers"
                  component="div"
                  className="text-red-500"
                />

                <Field
                  className="mt-2"
                  rows="3"
                  as="textarea"
                  name="phone_numbers"
                  id="phone_numbers"
                />
              </div>

              <div>
                <button type="submit" className="btn btn-primary">
                  UnBlock Users
                </button>
              </div>
            </Form>
          </div>
        </DashboardLayout>
      )}
    </Formik>
  );
}

export default TemporaryUnBlockUser;
