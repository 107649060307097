import axios from "axios";
import Cookies from "js-cookie";
import ApiUrl from "../../config/ApiUrl";

const apiToken = Cookies.get("apiToken", {path: "/"});

async function registerUser (formFields)
{
    return await axios.post(`${ApiUrl.customers.registerUser}/${formFields.partner_code}/register-user`, formFields, {
        headers: {
            "X-Authorization": apiToken
        }
    });
}

async function deRegisterUser (formFields)
{
    return await axios.post(ApiUrl.customers.deRegisterUser, {
        _method: "DELETE",
        phone_numbers: [formFields.phone_numbers]
    }, {
        headers: {
            "X-Authorization": apiToken
        }
    });
}

async function userPlanDetails (formFields)
{
    return await axios.get(`${ApiUrl.customers.userPlanDetails}?phone=${formFields.phone}`, {
        headers: {
            "X-Authorization": apiToken
        }
    });
}

async function userBandwidthConsumption (dabbaUserPlanId)
{
    return await axios.get(`${ApiUrl.customers.userBandwidthConsumption}?plan_id=${dabbaUserPlanId}`, {
        headers: {
            "X-Authorization": apiToken
        }
    });
}

export {
    registerUser,
    deRegisterUser,
    userPlanDetails,
    userBandwidthConsumption
};
