import axios from "axios";
import Cookies from "js-cookie";
import ApiUrl from "../../config/ApiUrl";

const apiToken = Cookies.get("apiToken", {path: "/"});

async function sendSMSNotification (formFields)
{
    return await axios.post(ApiUrl.customerSupport.sendSMSNotification , formFields, {
        headers: {
            "X-Authorization": apiToken
        }
    });
}

export {
    sendSMSNotification
};
