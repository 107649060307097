import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import ProtectedRoute from "../components/ProtectedRoute";
import Dashboard from "../pages/dashboard/Dashboard";
import Properties from "../pages/properties/Properties";
import PropertiesOnMap from "../pages/properties/PropertiesOnMap";
import CreateProperty from "../pages/properties/CreateProperty";
import Pops from "../pages/pops/Pops";
import CreateUser from "../pages/customers/CreateUser";
import RemoveUser from "../pages/customers/RemoveUser";
import UserPlanDetails from "../pages/customers/UserPlanDetails";
import AddUserDevice from "../pages/customers/AddUserDevice";
import TemporaryBlockUser from "../pages/customers/TemporaryBlockUser";
import TemporaryUnBlockUser from "../pages/customers/TemporaryUnBlockUser";
import SmsNotification from "../pages/customer_support/SmsNotification";
import PlanRecharge from "../pages/payments/PlanRecharge";
import DashboardError from "../pages/errors/DashboardError";
import Login from "../pages/auth/Login";
import BandwidthConsumption from "../pages/customers/BandwidthConsumption";
import UserDetails from "../pages/customers/userDetails";
import PropertyDevices from "../pages/properties/PropertyDevices";

export default function RouteLinks() {
  return (
    <Router>
      <Routes>
        {/* Auth Routes */}
        <Route exact path="/" element={<Login />}></Route>
        <Route path="/auth/login" element={<Login />}></Route>

        {/* Authenticated Routes */}

        {/* Dashboard */}
        <Route
          path="/dashboard"
          element={<ProtectedRoute element={<Dashboard />} />}
        ></Route>

        {/* Properties Routes */}
        <Route
          path="/properties"
          element={<ProtectedRoute element={<Properties />} />}
        ></Route>
        <Route
          path="/properties/:id"
          element={<ProtectedRoute element={<PropertyDevices />} />}
        ></Route>
        {/* <Route
          path="/properties/create"
          element={<ProtectedRoute element={<CreateProperty />} />}
        ></Route> */}
        {/* <Route
          path="/properties/on-map"
          element={<ProtectedRoute element={<PropertiesOnMap />} />}
        ></Route> */}

        {/* Pop Routes */}
        <Route
          path="/pops"
          element={
            <ProtectedRoute element={<ProtectedRoute element={<Pops />} />} />
          }
        ></Route>

        {/* Payment Routes */}
        <Route
          path="/payments/plan-recharge"
          element={
            <ProtectedRoute
              element={<ProtectedRoute element={<PlanRecharge />} />}
            />
          }
        ></Route>

        {/* Customer Routes */}
        <Route
          path="/customers/create-user"
          element={<ProtectedRoute element={<CreateUser />} />}
        ></Route>
        <Route
          path="/customers/remove-user"
          element={<ProtectedRoute element={<RemoveUser />} />}
        ></Route>
        <Route
          path="/customers/plan-details"
          element={<ProtectedRoute element={<UserPlanDetails />} />}
        ></Route>
        <Route
          path="/customers/property-customers"
          element={<ProtectedRoute element={<UserDetails />} />}
        ></Route>
        <Route
          path="/customers/add-user-device"
          element={<ProtectedRoute element={<AddUserDevice />} />}
        ></Route>
        <Route
          path="/customers/temporary-block-user"
          element={<ProtectedRoute element={<TemporaryBlockUser />} />}
        ></Route>
        <Route
          path="/customers/temporary-unblock-user"
          element={<ProtectedRoute element={<TemporaryUnBlockUser />} />}
        ></Route>
        <Route
          path="/customers/bandwidth-consumption-details/:dabbaUserPlanId"
          element={<ProtectedRoute element={<BandwidthConsumption />} />}
        ></Route>

        {/* Customer Support Routes */}
        <Route
          path="/customer-support/sms-notification"
          element={<ProtectedRoute element={<SmsNotification />} />}
        ></Route>

        {/* Error Page */}
        <Route
          path="*"
          element={<ProtectedRoute element={<Dashboard />} />}
        ></Route>
      </Routes>
    </Router>
  );
}
