function UserRegistrationDetails(props) {
  return (
    <div className="col-span-12 md:col-span-5 flex flex-col border-gray-500 shadow rounded-sm bg-white p-5">
      <h2 className="text-xl text-gray-600">User Registration Details</h2>
      <hr className="my-4" />
      <table className="w-full">
        <tbody>
          <tr>
            <td className="py-1 text-gray-500 font-medium">Status</td>
            <td className="py-1 font-semibold">
              {props?.userPlan?.blocked_at ? (
                <span className="text-red-600">Temporary Blocked</span>
              ) : (
                <span className="text-indigo-600">Active</span>
              )}
            </td>
          </tr>
          <tr>
            <td className="py-1 text-gray-500 font-medium">Phone</td>
            <td className="py-1">{props.userPlan.phone}</td>
          </tr>
          <tr>
            <td className="py-1 text-gray-500 font-medium">
              Last Requested OTP
            </td>
            <td className="py-1">
              {props.userPlan?.password_resets.length
                ? props.userPlan?.password_resets[
                    props.userPlan?.password_resets.length - 1
                  ]?.token
                : "N/A"}
            </td>
          </tr>
          <tr>
            <td className="py-1 text-gray-500 font-medium">
              Current Data Pack Status
            </td>
            <td className="py-1">
              {props.userPlan?.user_plans?.current_plan ? "ACTIVE" : "INACTIVE"}
            </td>
          </tr>
          <tr>
            <td className="py-1 text-gray-500 font-medium">Verified User</td>
            <td className="py-1">
              {props.userPlan?.partner?._id ? "YES" : "NO"}
            </td>
          </tr>
          <tr>
            <td className="py-1 text-gray-500 font-medium">
              Registered Property
            </td>
            <td className="py-1">{props.userPlan?.dabba?.property_name}</td>
          </tr>
          <tr>
            <td className="py-1 text-gray-500 font-medium">Registered Date</td>
            <td className="py-1">{props.userPlan?.dabba?.created_at}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default UserRegistrationDetails;
