import React from "react";
import DashboardLayout from "../../layouts/DashboardLayout";

function Pops() {
    return (
        <DashboardLayout>
            <div>Pops</div>
        </DashboardLayout>
    );
}

export default Pops;
