import * as yup from "yup";

const planRechargeValidation = yup.object().shape({
  phone: yup
    .string()
    .required("Phone number is required.")
    .length(10, "Phone number must be 10 digits."),
  dabba_custom_plan_id: yup.string().required("Recharge plan is required."),
  topup_type: yup
    .string()
    .required("TopUp type is required.")
    .oneOf(["COMPLIMENTARY", "TOPUP"], "Select appropriate topup type."),
});

export default planRechargeValidation;
