import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import DashboardLayout from "../../layouts/DashboardLayout";
import { userBandwidthConsumption } from "../../services/customers/CustomerService";
import axios from "../../helpers/axios";

function BandwidthConsumption() {
  let params = useParams();

  const [bandwidthConsumption, setBandwidthConsumption] = useState({});
  const [overallBandwidth, setOverallBandwidth] = useState(0);

  const getUserPlanDetails = async () => {
    try {
      const res = await (
        await axios.get(`api/users/user-plans/${params.dabbaUserPlanId}`)
      ).data;

      if (res.status === "success") {
        setBandwidthConsumption(res.data.userPlan);
      } else {
        throw new Error("Something went wrong!");
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  useEffect(async () => {
    getUserPlanDetails();
  }, []);

  return (
    <DashboardLayout>
      <div className="flex justify-between align-middle">
        <h1 className="font-bold text-3xl">Bandwidth Consumption</h1>
      </div>

      {bandwidthConsumption?.user?.phone ? (
        <div className="col-span-12 border-gray-500 shadow rounded-sm bg-white p-5 mt-6 text-xl text-gray-600">
          <div className="mt-4">
            Phone:{" "}
            <span className="text-indigo-600">
              {bandwidthConsumption.user.phone}
            </span>
          </div>
          <div className="mt-4">
            Total Bandwidth Consumed:
            <span className="text-indigo-600">
              {` ${(
                bandwidthConsumption.bandwidth_consumed_in_bytes /
                (1000 * 1000 * 1000)
              ).toFixed(2)}
            GB | ${(
                  bandwidthConsumption.bandwidth_purchased_in_bytes /
                  (1000 * 1000 * 1000)
                ).toFixed(2)} GB`}
            </span>
          </div>
        </div>
      ) : null}
      {bandwidthConsumption?.bandwidth_consumptions?.length ? (
        <div className="col-span-12 border-gray-500 shadow rounded-sm bg-white p-5 mt-6">
          <table className="w-full">
            <thead>
              <tr className="py-1">
                <td className="text-gray-500 font-medium">Data Consumed</td>
                <td className="text-gray-500 font-medium">Mac Address</td>
                <td className="text-gray-500 font-medium">Date</td>
              </tr>
            </thead>
            <tbody className="text-sm">
              {/* {bandwidthConsumption?.bandwidthDetails?.length > 0 &&
                bandwidthConsumption.bandwidthDetails.map((bandwidth) => {
                  return (
                    <tr key={bandwidth.id} className="hover:bg-gray-100">
                      <td className="py-1">
                        {bandwidth.upload_bytes &&
                          (bandwidth.upload_bytes / (1000 * 1000)).toFixed(2)}
                      </td>
                      <td className="py-1">
                        {bandwidth.download_bytes &&
                          (bandwidth.download_bytes / (1000 * 1000)).toFixed(2)}
                      </td>
                      <td className="py-1">
                        {bandwidth.dabba_user_device_trashed.mac_address}
                      </td>
                      <td className="py-1">{bandwidth.start_reason}</td>
                      <td className="py-1">{bandwidth.stop_reason ?? "N/A"}</td>
                      <td className="py-1">{bandwidth.created_at}</td>
                      <td className="py-1">{bandwidth.updated_at}</td>
                    </tr>
                  );
                })} */}

              {bandwidthConsumption.bandwidth_consumptions.map((bandwidth) => {
                return (
                  <tr key={bandwidth.mac} className="hover:bg-gray-100">
                    <td className="py-1">
                      {bandwidth.bandwidth_consumed_in_kb &&
                        (bandwidth.bandwidth_consumed_in_kb / 1000).toFixed(2)}
                      &nbsp;MB
                    </td>
                    <td className="py-1">{bandwidth.mac}</td>
                    <td className="py-1">
                      {bandwidth.timestamp
                        ? new Date(bandwidth.timestamp).toLocaleString()
                        : null}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      ) : null}
    </DashboardLayout>
  );
}

export default BandwidthConsumption;
