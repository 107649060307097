import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import DashboardLayout from "../../layouts/DashboardLayout";
import { partners } from "../../services/partners/PartnerService";
import { propertiesList } from "../../services/properties/PropertyService";
import { toast } from "react-toastify";
import searchPropertiesValidation from "../../validations/properties/searchPropertiesValidation";
import axios from "../../helpers/axios";
import { useNavigate } from "react-router-dom";
function Properties() {
  console.log("Got list of all the properties");
  const [partnersList, setPartnersList] = useState([]);
  const [properties, setProperties] = useState([]);
  const navigate = useNavigate();
  const getPartnersList = async () => {
    try {
      const res = await (await axios.get("api/partners")).data;

      if (res.status === "success") {
        setPartnersList(res.data.partners);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // const getProperties = async () => {
  //   try {
  //     const res = await (
  //       await axios.get(`/api/dabbas?hasAccessPoints=false`)
  //     ).data;

  //     if (res.status === "success") {
  //       setProperties(res.data.dabbas.docs);
  //     } else {
  //       console.log(error);
  //     }
  //   } catch (error) {
  //     console.log();
  //     //   toast.error(error.response.data.message);
  //   }
  // };

  const getProperties = async (partnerCode = "HelloWorld") => {
    try {
      const res = await (
        await axios.get(
          `/api/dabbas?partner=${partnerCode}&hasAccessPoints=false`
        )
      ).data;

      if (res.status === "success") {
        setProperties(res.data.dabbas);
      } else {
        console.log(error);
      }
    } catch (error) {
      console.log(error.response.data.message);
      toast.error(error.response.data.message);
    }
  };

  const handleRowClick = (id) => {
    navigate(`/properties/${id}`);
  };

  useEffect(() => {
    getPartnersList();
    if (partnersList.length) {
      getProperties(partnersList[0].code);
    }
  }, [partnersList.length]);

  //
  return (
    <Formik
      initialValues={{
        partner_id: partnersList.length ? partnersList[0].code : "",
      }}
      validationSchema={searchPropertiesValidation}
    >
      {({ setFieldValue }) => (
        <DashboardLayout>
          <div className="flex justify-between">
            <h1 className="font-bold text-3xl">Properties</h1>

            <Form className="space-x-3">
              <div>
                <label htmlFor="partner_id">Partner *</label>

                <ErrorMessage
                  name="partner_id"
                  component="div"
                  className="text-red-500"
                />

                <Field
                  as="select"
                  name="partner_id"
                  id="partner_id"
                  onChange={(event) => {
                    setFieldValue("partner_id", event.target.value);
                    getProperties(event.target.value);
                  }}
                >
                  {partnersList.length ? (
                    partnersList.map((partner) => {
                      return (
                        <option
                          data-partner-code={partner.code}
                          value={partner.code}
                          key={partner._id}
                        >
                          {partner.name}
                        </option>
                      );
                    })
                  ) : (
                    <option>Select Partner</option>
                  )}
                </Field>
              </div>
            </Form>
          </div>
          <div className="border-gray-500 shadow rounded-sm bg-white p-5 mt-6 overflow-x-scroll">
            <table className="table table-border table-hover">
              <thead>
                <tr className="bg-gray-100">
                  <th>WD Number</th>
                  <th>Lat / Long</th>
                  <th>Property Name</th>
                  <th>VPN IP Address</th>
                  <th>Status</th>
                  <th>Last Ping On</th>
                </tr>
              </thead>
              <tbody>
                {properties.length
                  ? properties.map((property) => {
                      const dateDiffInMilliSeconds = Math.abs(
                        new Date(property?.heartbeat_on) - new Date()
                      );

                      const dateDiffInMinutes =
                        dateDiffInMilliSeconds / (1000 * 60);

                      const status = dateDiffInMinutes > 20 ? "Down" : "Live";

                      return (
                        <tr
                          key={property._id}
                          className={`${
                            status == "Down" && "bg-red-100 text-red-500"
                          } cursor-pointer`}
                        >
                          <td
                            className={`${
                              status == "Down" ? "border-red-500" : ""
                            } text-indigo-600`}
                            onClick={() => handleRowClick(property._id)}
                          >
                            {property.wd_number}
                          </td>
                          <td
                            className={`${
                              status == "Down" ? "border-red-500" : ""
                            }`}
                          >
                            {(property.lat && property.long) || "N/A"}
                          </td>
                          <td
                            className={`${
                              status == "Down" ? "border-red-500" : ""
                            }`}
                          >
                            {property.property_name}
                          </td>
                          <td
                            className={`${
                              status == "Down" ? "border-red-500" : ""
                            }`}
                          >
                            {property.client_vpn_ip_address || "N/A"}
                          </td>
                          <td
                            className={`${
                              status == "Down" ? "border-red-500" : ""
                            }`}
                          >
                            {status}
                          </td>
                          <td
                            className={`${
                              status == "Down" ? "border-red-500" : ""
                            }`}
                          >
                            {property?.heartbeat_on
                              ? new Date(
                                  property?.heartbeat_on
                                ).toLocaleString()
                              : "N/A"}
                          </td>
                        </tr>
                      );
                    })
                  : null}
              </tbody>
            </table>
          </div>
        </DashboardLayout>
      )}
    </Formik>
  );
}

export default Properties;
