import { toast } from "react-toastify";
import {
  activateUserDevice,
  deactivateUserDevice,
  logoutAllDevices,
} from "../../services/customers/CustomerDevicesService";
import axios from "../../helpers/axios";

function UserDeviceDetails(props) {
  const logoutDevices = async (phone) => {
    try {
      const res = await (
        await axios.post("/api/auth/logout-all-devices-by-phone", { phone })
      ).data;

      if (res.status === "success") {
        toast.success("Logged out from all devices succesfully");
      } else {
        throw new Error("Something went wrong!");
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const enableDevice = async (macAddress) => {
    try {
      const res = await (
        await axios.post("/api/users/devices/enable-device", {
          macAddress: macAddress || null,
        })
      ).data;
      if (res.status === "success") {
      } else {
        throw new Error("Something went wrong!");
      }
    } catch (error) {
      console.log(error.response.data);
      toast.error(error.response.data.message);
    }
  };

  const deleteDevice = async (macAddress) => {
    try {
      const res = await (
        await axios.delete("/api/users/devices", {
          data: { macAddress: macAddress || null },
        })
      ).data;
      if (res.status === "success") {
        toast.success("Device Deleted Successfully!");
      } else {
        throw new Error("Something went wrong");
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
  };

  return (
    <div className="col-span-12 md:col-span-7 flex flex-col border-gray-500 shadow rounded-sm bg-white p-5 overflow-y-auto max-h-80 mt-10 md:mt-0">
      <div className="flex justify-between">
        <h2 className="text-xl text-gray-600">User Device Details</h2>
        <button
          type="button"
          className="text-indigo-600"
          onClick={() => logoutDevices(props.userPlan.phone)}
        >
          Logout all devices
        </button>
      </div>

      <hr className="my-4" />

      <div>
        <table className="table table-border table-hover">
          <thead>
            <tr className="bg-gray-100">
              <td>MAC Address</td>
              <td>Device Status</td>
            </tr>
          </thead>
          <tbody className="text-sm">
            {props.userPlan.user_devices.length
              ? props.userPlan.user_devices.map((device) => {
                  return (
                    <tr key={device._id}>
                      <td>{device.mac_address}</td>
                      <td
                        data-user-id={props.userPlan.user_devices.unique_id}
                        data-mac-address={device.mac_address}
                      >
                        {device.deleted_at ? (
                          <button
                            type="button"
                            onClick={() => enableDevice(device.mac_address)}
                            className="text-green-600 font-semibold"
                          >
                            Activate
                          </button>
                        ) : (
                          <button
                            type="button"
                            onClick={() => deleteDevice(device.mac_address)}
                            className="text-red-600 font-semibold"
                          >
                            Deactivate
                          </button>
                        )}
                      </td>
                    </tr>
                  );
                })
              : null}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default UserDeviceDetails;
