import * as yup from "yup";

const addUserDeviceValidation = yup.object().shape({
  phone: yup
    .string()
    .length(10, "Enter valid phone number of length 10")
    .required("Phone number field is required."),
  mac_address: yup
    .string()
    .max(17, "Maximum input limit reached")
    .required("Device mac address is required."),
  notes: yup.string().nullable(),
});

export default addUserDeviceValidation;
