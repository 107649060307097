import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import DashboardLayout from "../../layouts/DashboardLayout";
import { partners } from "../../services/partners/PartnerService";
import { partnerWiseProperties } from "../../services/properties/PropertyService";
import { sendSMSNotification } from "../../services/customerSupport/SMSNotificationService";
import { toast } from "react-toastify";
import SMSNotificationValidation from "../../validations/customerSupport/SMSNotificationValidation";

function SmsNotification() {
    const [partnersList, setPartnersList] = useState([]);
    const [propertiesList, setPropertiesList] = useState([]);

    const getPartners = async () => {
        try {
            const partnersResponse = await partners();
            setPartnersList(partnersResponse.data.data.partners);
        } catch (error) {
            console.log(error);
        }
    };

    const getPartnerProperties = async (partnerId) => {
        try {
            const propertiesResponse = await partnerWiseProperties(partnerId);
            setPropertiesList(propertiesResponse.data.data.dabbas);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getPartners();
    }, []);

    return (
        <Formik
            initialValues={{ 
                partner_id: "",
                business_dabba_ids: [],
                sms_template: ""
            }}

            validationSchema={SMSNotificationValidation}

            onSubmit={async (values, {resetForm, setFieldError}) => {
                try {
                    const notification = await sendSMSNotification(values);
                    
                    resetForm({values: ""});

                    toast.success(notification.data.message);
                } catch (error) {
                    Object.entries(error.response.data.errors).map((error) => {
                        if(error[0] && error[1]) {
                            setFieldError(error[0], error[1]);
                        }
                    });

                    toast.error(error.response.data.message);
                }
            }}
        >
            {
                (props) => (
                    <DashboardLayout>
                        <div className="flex justify-between align-middle">
                            <h1 className="font-bold text-3xl">SMS Notification</h1>
                        </div>
                        <div className="border-gray-500 shadow rounded-sm bg-white p-5 mt-6">
                            <div className="grid grid-cols-12">
                                <Form onSubmit={props.handleSubmit} className="space-y-4 col-span-12 md:col-span-6 lg:col-span-4">
                                    <div>
                                        <label htmlFor="partner_id">Partner *</label>

                                        <ErrorMessage name="partner_id" component="div" className="text-red-500" />
                                        
                                        <Field as="select" name="partner_id" id="partner_id" 
                                            onChange={(event) => {
                                                props.setFieldValue("partner_id", event.target.value);

                                                getPartnerProperties(event.target.value);
                                            }}
                                        >
                                            <option value="">Select Partner</option>

                                            {
                                                partnersList && partnersList.map( (partner) => {
                                                    return <option  value={partner.unique_id} key={partner.id}>{partner.name}</option>;
                                                })
                                            }
                                        </Field>
                                    </div>

                                    <div>
                                        <label htmlFor="business_dabba_ids">Property *</label>

                                        <ErrorMessage name="business_dabba_ids" component="div" className="text-red-500" />
                                        
                                        <Field as="select" multiple={true} name="business_dabba_ids" id="business_dabba_ids">
                                            {
                                                propertiesList && propertiesList.map( (property) => {
                                                    return <option data-partner-code={property.partner_code} value={property.unique_id} key={property.id}>{property.business_name}</option>;
                                                })
                                            }
                                        </Field>
                                    </div>

                                    <div>
                                        <label htmlFor="sms_template">SMS Template *</label>

                                        <ErrorMessage name="sms_template" component="div" className="text-red-500" />
                                        
                                        <Field rows="3" as="textarea" name="sms_template" id="sms_template" />
                                    </div>

                                    <div className="pt-2">
                                        <button type="submit" className="btn btn-primary">
                                            Send Notification
                                        </button>
                                    </div>
                                </Form>
                                
                                <div className="col-span-12 md:col-span-6 lg:ml-10 md:px-4 mt-10 md:mt-0">
                                    <div className="text-xl">SMS Templates</div>

                                    <div className="mt-6 text-gray-600">
                                        Hello. Your Wifi may be experiencing issues. Our team is working on it. We will update you when the issue is resolved.
                                    </div>

                                    <div className="mt-6 text-gray-600">
                                        Hi, Wifi services are live and running. Sorry for the inconvenience caused.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </DashboardLayout>
                )
            }
        </Formik>
    );
}

export default SmsNotification;
