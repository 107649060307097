import * as yup from "yup";

const createUserValidation = yup.object().shape({
  dabba_id: yup.string().required("Property of user is required."),
  phone: yup.string().required("Phone number is required."),
  mac_address: yup.string().nullable(),
  is_property_manager: yup.boolean(),
});

export default createUserValidation;
