import { ErrorMessage, Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import DashboardLayout from "../../layouts/DashboardLayout";
import {
  partnerWiseProperties,
  upTimeData,
} from "../../services/properties/PropertyService";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import dashboardFilterValidation from "../../validations/dashboard/dashboardFilterValidation";
import { toast } from "react-toastify";
import { format, parseISO, isValid } from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import axios from "../../helpers/axios";
function upTimeXAxisFormatter(date) {
  if (
    isValid(parseISO(date)) != false &&
    format(parseISO(date), "H") > 1 &&
    format(parseISO(date), "H") % 3 === 0
  ) {
    return format(parseISO(date), "h:mm a");
  }

  return "";
}

function UpTimeToolTip({ active, payload, label }) {
  if (active) {
    return (
      <div className="bg-gray-700 text-white rounded-md p-3">
        <h2 className="text-lg text-indigo-300 font-medium">{label}</h2>
        <p>Status: {payload[0]?.value == 1 ? "Up" : "Down"}</p>
      </div>
    );
  }

  return null;
}

function Dashboard() {
  const [partnerPropertiesList, setPartnerPropertiesList] = useState([]);
  const [upTimeDataList, setUpTimeDataList] = useState([{}]);
  const [defaultBusinessDabbaId, setDefaultBusinessDabbaId] = useState("");

  const upTimeApiData = async (formFields) => {
    try {
      const upTimeResponse = await upTimeData(formFields);

      setUpTimeDataList(upTimeResponse.data.data);
      toast.success(upTimeResponse.data.message);
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const getPartnersList = async () => {
    try {
      const res = await (await axios.get("api/partners")).data;
      if (res.status === "success") {
        setPartnerPropertiesList(res.data.partners);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getPartnersList();
  }, []);

  return (
    <Formik
      initialValues={{
        business_dabba_id: defaultBusinessDabbaId,
        from_date: new Date(),
      }}
      validationSchema={dashboardFilterValidation}
      onSubmit={(values) => {
        upTimeApiData(values);
      }}
    >
      {(props) => (
        <DashboardLayout>
          <div className="flex justify-between align-middle">
            <h1 className="font-bold text-3xl">User Plan Information</h1>
          </div>

          <Form
            onSubmit={props.handleSubmit}
            className="grid grid-cols-12 space-x-2 md:space-x-4 mt-5"
          >
            <div className="col-span-5  md:col-span-3">
              <label htmlFor="business_dabba_id">Property *</label>

              <ErrorMessage
                name="business_dabba_id"
                component="div"
                className="text-red-500"
              />

              <Field
                component="select"
                value={defaultBusinessDabbaId}
                name="business_dabba_id"
                id="business_dabba_id"
                onChange={(event) => {
                  props.setFieldValue("business_dabba_id", event.target.value);
                  setDefaultBusinessDabbaId(event.target.value);
                }}
              >
                <option value="">Select Property</option>
                {partnerPropertiesList &&
                  partnerPropertiesList.map((property) => {
                    return (
                      <option
                        data-partner-code={property.code}
                        value={property._id}
                        key={property._id}
                      >
                        {property.name}
                      </option>
                    );
                  })}
              </Field>
            </div>

            {/* <div className="col-span-5 md:col-span-2">
              <label htmlFor="from_date">Date *</label>

              <ErrorMessage
                name="from_date"
                component="div"
                className="text-red-500"
              />

              <DatePicker
                name="from_date"
                id="from_date"
                selected={props.values.from_date}
                dateFormat="yyyy-MM-dd"
                onSelect={(dateValue) =>
                  props.setFieldValue("from_date", dateValue)
                }
                onChange={(dateValue) =>
                  props.setFieldValue("from_date", dateValue)
                }
              />
            </div> */}

            <div className="col-span-2 md:col-span-2 mt-6">
              <button type="submit" className="btn btn-primary">
                <span>
                  <FontAwesomeIcon
                    className="shrink-0 h-4 w-4"
                    icon={faMagnifyingGlass}
                  />
                </span>
                <span className="hidden md:inline">Filter</span>
              </button>
            </div>
          </Form>
          <div className="grid grid-cols-12 space-4 mt-8">
            {/* <div className="col-span-12 p-5 rounded-sm">
              {upTimeDataList && (
                <ResponsiveContainer width="100%" height={350}>
                  <AreaChart data={upTimeDataList} margin={{ left: -20 }}>
                    <defs>
                      <linearGradient id="colorUv" x1="1" y1="0" x2="0" y2="1">
                        <stop
                          offset="5%"
                          stopColor="#4f46e5"
                          stopOpacity={0.6}
                        />
                        <stop
                          offset="95%"
                          stopColor="#4f46e5"
                          stopOpacity={0}
                        />
                      </linearGradient>
                    </defs>

                    <Area
                      type="monotone"
                      dataKey="1"
                      stroke="#4f46e5"
                      strokeWidth={3}
                      fillOpacity={1}
                      fill="url(#colorUv)"
                    />

                    <XAxis
                      dataKey="0"
                      tickLine={false}
                      tickFormatter={upTimeXAxisFormatter}
                    />

                    <YAxis
                      label={{
                        value: "Up/Down",
                        angle: -90,
                        postition: "left",
                      }}
                      tickCount={2}
                      tickLine={false}
                    />

                    <CartesianGrid opacity={0.2} vertical={false} />

                    <Tooltip content={<UpTimeToolTip />} />
                  </AreaChart>
                </ResponsiveContainer>
              )}
            </div> */}
          </div>
        </DashboardLayout>
      )}
    </Formik>
  );
}

export default Dashboard;
