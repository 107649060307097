import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import DashboardLayout from "../../layouts/DashboardLayout";
import { deRegisterUser } from "../../services/customers/CustomerService";
import { toast } from "react-toastify";
import removeUserValidation from "../../validations/customers/removeUserValidation";
import axios from "../../helpers/axios";
function RemoveUser() {
  const [deregisteredUsers, setDeRegisteredUsers] = useState(null);
  const [usersNotFound, setUsersNotFound] = useState(null);

  const removeUser = async (values) => {
    try {
      const res = await (
        await axios.delete("/api/users/", {
          data: { phone: values.phone_numbers || null },
        })
      ).data;

      if (res.status === "success") {
        setDeRegisteredUsers(res.data.deregistered_users);
        setUsersNotFound(res.data.users_not_found);
        toast.success("User removed succesfully!");
      } else {
        throw new Error("Something went wrong!");
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  return (
    <Formik
      initialValues={{
        phone_numbers: "",
      }}
      validationSchema={removeUserValidation}
      onSubmit={(values) => {
        removeUser(values);
      }}
    >
      {(props) => (
        <DashboardLayout>
          <div className="flex justify-between align-middle">
            <h1 className="font-bold text-3xl">Remove User</h1>
            <Link to="/customers/create-user" className="btn btn-primary">
              Property List
            </Link>
          </div>
          <div className="col-span-12 border-gray-500 shadow rounded-sm bg-white p-5 mt-6">
            <Form onSubmit={props.handleSubmit} className="space-y-4">
              {deregisteredUsers && deregisteredUsers.length > 0 && (
                <div className="bg-indigo-200 text-indigo-600 p-5 rounded-sm mb-3 text-sm">
                  <span className="font-semibold">De-registered users:</span>{" "}
                  {deregisteredUsers.map((user) => {
                    return `${user},`;
                  })}
                </div>
              )}

              {usersNotFound && usersNotFound.length > 0 && (
                <div className="bg-red-200 text-red-600 p-5 rounded-sm mb-3 text-sm">
                  <span className="font-semibold">Users not found:</span>{" "}
                  {usersNotFound.map((user) => {
                    return `${user},`;
                  })}
                </div>
              )}

              <div className="md:w-1/3">
                <label htmlFor="phone_numbers">Phone Number</label>

                <ErrorMessage
                  name="phone_numbers"
                  component="div"
                  className="text-red-500"
                />

                <Field type="text" name="phone_numbers" id="phone_numbers" />
              </div>

              <div>
                <button type="submit" className="btn btn-primary">
                  Remove User
                </button>
              </div>
            </Form>
          </div>
        </DashboardLayout>
      )}
    </Formik>
  );
}

export default RemoveUser;
