import axios from "axios";
import Cookies from "js-cookie";
import ApiUrl from "../../config/ApiUrl";

const apiToken = Cookies.get("apiToken", {path: "/"});

async function temporaryBlockUser (formFields)
{
    return await axios.post(ApiUrl.customers.temporaryBlockUser, formFields, {
        headers: {
            "X-Authorization": apiToken
        }
    });
}

async function temporaryUnBlockUser (formFields)
{
    return await axios.post(ApiUrl.customers.temporaryUnBlockUser, formFields, {
        headers: {
            "X-Authorization": apiToken
        }
    });
}

export {
    temporaryBlockUser,
    temporaryUnBlockUser
};
